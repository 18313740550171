<template>
<div>
  <section>
      <AdvanceTable ref="advanceTable" url="erp/email-template" :height="window.height - 310" tableSize="sm" :columns="columns" :baseFilters="{isShowDeleted: isShowDeleted}" v-loading="isLoading" @edit="openModalForEdit">
        <template #button>
          <el-switch
            v-model="isShowDeleted"
            active-text="Show Deleted"
            inactive-text=""
            class="mr-1" @change="reload()">
          </el-switch>

          <b-button variant="info" class="mr-1" @click="openModalForCreate">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">{{ $t("Email Template") }}</span>
          </b-button>
        </template>
        <template #cell(actions)="row">
          <el-link icon="el-icon-edit" @click="openModalForEdit(row.item)">{{$t('Edit')}}</el-link>
          <el-link icon="el-icon-delete" @click="openModalForDelete(row.item)">{{$t('Delete')}}</el-link>
          <el-link icon="el-icon-question" @click="openModalForTest(row.item)">{{$t('Test')}}</el-link>
          <el-link icon="el-icon-search" @click="showAudits(row.item)">{{$t('Audits')}}</el-link>
        </template>
      </AdvanceTable>
  </section>

  <el-dialog
    class="compact"
    :title="$t(title)"
    :visible.sync="isShow"
  >
    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Template Name')">
          <el-input v-model="row.name" ref="input"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Subject')">
          <el-input v-model="row.title" ref="input"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-form-group>
          <template #label>
            {{ $t('Message') }}
            <span style="float: right; cursor: pointer;" @click="showHelp()"><i class="el-icon-search"></i> Help</span>
          </template>
          <el-input v-model="row.content" ref="input" type="textarea" :autosize="{ minRows: 5, maxRows: 10}"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Signature')">
          <el-input type="textarea" v-model="row.signature" ref="input" :autosize="{ minRows: 3, maxRows: 10}"/>
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <div class="w-100 d-flex justify-content-end">
        <b-button variant="info" class="btn mr-2" @click="isShow=false;$refs.advanceTable.loadList()">
          {{ $t('Cancel') }}
        </b-button>
        <b-button variant="primary" class="btn" @click="save()">
          {{ $t('Save') }}
        </b-button>
      </div>
    </div>
  </el-dialog>

  <EmailTestModal ref="emailTestView"/>
  <AuditTrailModal ref="auditTrail"/>
  </div>
</template>

<script>
import { getErpProfile } from "@/utils/account-localstorage";

import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import apis from "@/configs/apis";
import { BModal } from "bootstrap-vue";
import EmailTestModal from "@/views/erp/components/EmailTestModal.vue";
import Prompt from "@/views/erp/mixins/Prompt";
import AuditTrailModal from "@/views/erp/components/AuditTrailModal.vue";
import axios from "axios";
import _ from "lodash";

export default {
  mixins: [Prompt],
  components: {
    AuditTrailModal,
    EmailTestModal,
    BModal,
    AdvanceTable,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      columns: [
        { key: "name", modelName: "name", label: "Template Name", width: "400", filtertype: "input", sortable: true },
        { key: "title", modelName: "title", label: "Subject", filtertype: "input", sortable: true },
        { key: "createBy", modelName: "createBy", label: "Created By", width: "250", filtertype: "input", sortable: true },
        { key: "actions", modelName: "actions", label: "Actions", sortable: false, width: "300" },
      ],
      productList: [],
      isShow: false,
      isShowTest: false,
      testEmail: '',
      title: '',
      row: {},
      isShowDeleted: true,
      isLoading: false,
    };
  },
  computed: {
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {

  },
  methods: {
    getErpProfile,
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    reload() {
      this.$nextTick(() => {
        this.$refs.advanceTable.loadList();
      });
    },
    openModalForView(item) {
      this.$alert(item.content, 'Title: ' + item.title);
    },
    openModalForCreate() {
      Object.keys(this.row).forEach(key => (this.row[key] = ''));
      this.isShow = true
      this.action = 'CREATE'
      this.title = 'Add Email Template'
    },
    openModalForEdit(item) {
      this.row = _.clone(item);
      this.isShow = true
      this.action = 'EDIT'
      this.title = 'Edit Email Template'
    },
    openModalForDelete(item) {
      this.$confirm('Confirm delete this item?', 'Delete Item: ' + item.id).then(() => {
        const response = axios.delete(
          apis.erpEmailTemplates + "/" + item.id,
        ).then(response => {
          this.reload();
          this.promptInfo("Item Deleted");
        }).catch(e => {
          this.promptError(e);
        })
      }).catch(() => {});
    },
    openModalForTest(item) {
      this.$refs.emailTestView.showFromEmailTemplate(item);
    },
    showHelp() {
      this.$alert('{{Due Day}} = Payment due day<br/>' +
        '{{Finance Contact}} = Finance Contact Person<br/>' +
        '{{Company Name}} = Billing Company', 'Help Centre', {dangerouslyUseHTMLString: true}
      );
    },
    async save(){
      if (this.action === 'CREATE') {
        try {
          const response = await axios.post(
            apis.erpEmailTemplates,
            {
              "name": this.row.name,
              "title": this.row.title,
              "content": this.row.content,
              "signature": this.row.signature,
            }
          );
          this.reload();
          this.isShow = false

          this.promptInfo('Create Email Template Success');
        } catch (e) {
          this.promptError(e);
        }
      }else{
        try {
          const response = await axios.put(
            apis.erpEmailTemplates+'/'+this.row.id,
            {
              "name": this.row.name,
              "title": this.row.title,
              "content": this.row.content,
              "signature": this.row.signature,
            }
          );
          this.reload();
          this.isShow = false

          this.promptInfo('Update Email Template Success');
        } catch (e) {
          this.promptError(e);
        }
      }
    },

    showAudits(entity) {
      this.$refs.auditTrail.show({objectId: entity.id, className: 'com.kerrylogistics.dashboard.entities.erp.EmailTemplate', name: 'Email Template'});
    }
  }
};
</script>
